fuse-alert.fuse-alert-appearance-soft.fuse-alert-type-info {

    .fuse-alert-container {
        @apply bg-primary-100 #{'!important'};

        .fuse-alert-message {
            @apply text-primary-700 #{'!important'};
        }
    }

    .mat-icon {
        @apply text-primary-500 #{'!important'};
    }

}