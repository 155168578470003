/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

/* Overrides */
@use 'overrides/angular-material';
@use 'overrides/fuse';
@use 'overrides/mondial-relay';
@use 'overrides/swiper';

/* contain overscroll behaviour only in vertical direction */
body {
    overscroll-behavior-y: contain;
}