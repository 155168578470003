
/* ----------------------------------------------------------------------------------------------------- */
/*  @ Angular Material CDK helpers & overrides
/* ----------------------------------------------------------------------------------------------------- */

:root {
    --mat-select-panel-background-color: #ffffff;
}

/* ----------------------------------------------------------------------------------------------------- */
/*  @ Table
/* ----------------------------------------------------------------------------------------------------- */

.mat-table {
    @apply bg-transparent dark:bg-transparent #{'!important'};
    background: transparent!important;
}

body.dark .mat-focused .mat-form-field-required-marker,
body .dark .mat-focused .mat-form-field-required-marker {
    color: white;
}


/* ----------------------------------------------------------------------------------------------------- */
/*  @ Tabs
/* ----------------------------------------------------------------------------------------------------- */

.mat-tab-label-container {
    @apply px-0 #{'!important'};
}

.mat-tab-group .mat-tab-body-content {
    @apply px-0 #{'!important'};
}

.mat-mdc-tab-body-content {
    @apply px-0 #{'!important'};
}

/* ----------------------------------------------------------------------------------------------------- */
/*  @ Expansion Panel
/* ----------------------------------------------------------------------------------------------------- */

.mat-button-toggle-checked {
    @apply bg-primary-100 dark:bg-primary-800 #{'!important'};
}

.mat-expansion-panel-header-title {
    @apply font-normal #{'!important'};
}

/* ----------------------------------------------------------------------------------------------------- */
/*  @ Buttons
/* ----------------------------------------------------------------------------------------------------- */

.mdc-fab,
.mdc-button {
    
    @apply rounded-[6px] #{'!important'};

    &.mat-primary:not([disabled]) {
        @apply bg-primary;
    }

}

.mdc-button__label {
    
    @apply flex flex-row items-center #{'!important'};

    .mat-icon {
        @apply mt-1;
    }
}

/* ----------------------------------------------------------------------------------------------------- */
/*  @ Icons
/* ----------------------------------------------------------------------------------------------------- */

.mat-icon {

    &.mat-primary {
        @apply text-primary #{'!important'};
    }

}

/* ----------------------------------------------------------------------------------------------------- */
/*  @ Progress bar
/* ----------------------------------------------------------------------------------------------------- */

.mat-mdc-progress-bar {
    --mdc-linear-progress-active-indicator-color: unset !important;
}

/* ----------------------------------------------------------------------------------------------------- */
/*  @ Expansion Panel
/* ----------------------------------------------------------------------------------------------------- */

.mat-expansion-panel-body {
    @apply p-0 #{'!important'};
}

.mat-expansion-panel-header {
    @apply p-0 font-normal;
}

/* ----------------------------------------------------------------------------------------------------- */
/*  @ Radio Button
/* ----------------------------------------------------------------------------------------------------- */

.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled+.mdc-radio__background .mdc-radio__inner-circle,
.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__outer-circle {
    @apply border-primary #{'!important'};
}

/* ----------------------------------------------------------------------------------------------------- */
/*  @ Checkbox
/* ----------------------------------------------------------------------------------------------------- */

.mat-mdc-list-option .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
.mat-mdc-list-option .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background,
.mat-mdc-list-option .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
    @apply border-primary bg-primary #{'!important'};
}

/* ----------------------------------------------------------------------------------------------------- */
/*  @ Chips
/* ----------------------------------------------------------------------------------------------------- */

body .light .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-selected,
body .light .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-highlighted,
body.light .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-selected,
body.light .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-highlighted {
    @apply bg-primary #{'!important'};
}

/* ----------------------------------------------------------------------------------------------------- */
/*  @ Select
/* ----------------------------------------------------------------------------------------------------- */

body .light .mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text,
body.light .mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
    @apply text-primary #{'!important'};
}

body .light .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
body .light .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
body.light .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
body.light .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
    @apply text-primary #{'!important'};
}

body .light .mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow,
body.light .mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
    @apply text-primary #{'!important'};
}

/* ----------------------------------------------------------------------------------------------------- */
/*  @ Divider
/* ----------------------------------------------------------------------------------------------------- */

body .light .mat-divider, 
body.light .mat-divider {
    @apply border-slate-200 #{'!important'};
}
